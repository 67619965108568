import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useConnection from "./useConnection";
import { messageType, MessageControl } from "./useMessageControl";
import { addPresent } from "../store/ents/presentSlice";
import {
  setConnect,
  clearSession,
  markSent,
  setToken,
  setMaxVideo,
} from "../store/ents/clientSlice";

const Connection = () => {
  const session = useSelector((state) => state.client.session);
  const isConnect = useSelector((state) => state.client.connect);
  const sendList = useSelector((state) => state.client.send);
  const dispatch = useDispatch();

  const connection = useConnection({
    session: session,
    from: "client",
    initiate: true,
    setConStatus: (value) => dispatch(setConnect(value)),
  });
  const send = (id, type, message) => {
    connection.channel.send(
      JSON.stringify({
        id,
        type,
        message,
      })
    );
  };

  if (isConnect) {
    connection.channel.onclose = () => dispatch(clearSession());
    connection.channel.onopen = () => {
      MessageControl({
        channel: connection.channel,
        message: {
          present: (item) => dispatch(addPresent(item)),
          photoToken: (token) => dispatch(setToken(token)),
          maxVideo: (max) => dispatch(setMaxVideo(max)),
        },
      });
      //send inital setup width & height.
      send(1001, messageType.initial, {
        width: window.innerWidth,
        height: window.innerHeight,
      });
      // use list to send messages...
      // sendList.forEach((el, index) => {
      //   if (el.sent === false) {
      //     send(el.id, el.type, el.message);
      //     dispatch(markSent(index));
      //   }
      // });
    };
  }

  useEffect(() => { //use list to send messages
    sendList.forEach((el, index) => {
      if (el.sent === false) {
        send(el.id, el.type, el.message);
        dispatch(markSent(index));
      }
    });
  }, [sendList]);

  return null;
};

export default Connection;
