import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PaymentCCButton from "./PaymentCCButton";
import { selectPresentsById } from "../store/ents/presentSlice";

export default function TotalBar() {
  const list = useSelector((state) =>
    state.persist.cart.map((id) => selectPresentsById(state, id))
  );
  const [show, setShow] = useState(false);
  const tax = 0.05;

  let total = 0;
  list.forEach((item) => {
    total += parseFloat(item.present.price);
  });
  let subtotal = total.toFixed(2);

  function getTotal() {
    const withTax = subtotal * tax;
    const newTotal = parseFloat(withTax) + parseFloat(subtotal);
    return parseFloat(newTotal).toFixed(2);
  }
  function handleClick() {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  }

  function handleComplete(details) {
    alert(
      `Thank You ${details.name}! Your transcation of $ ${details.amount} has been approved. You will receive an email confirmation shortly.`
    );
    handleClick();
  }

  return (
    <React.Fragment>
      <div
        style={{
          width: "100%",
          height: show ? "100%" : "",
          position: "fixed",
          bottom: show ? 0 : 55,
          backgroundColor: "#efeff4",
          color: "#000000",
          opacity: 0.96,
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          border: 10,
          zIndex: 10,
          overflowY: "auto",
          maxWidth: 600,
        }}
      >
        <Grid container style={{ padding: 15 }}>
          {show && (
            <Grid item xs={12} style={{ height: "60px" }}>
              <center>
                <Typography variant="h4">Checkout</Typography>
                <hr width="50px" />
              </center>
            </Grid>
          )}

          <Grid container item xs={6} alignItems="center">
            <Button variant="outlined" color="primary" onClick={handleClick}>
              {show === true ? "Cancel" : "CheckOut"}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2"> Subtotal</Typography>
            <Typography variant="body2"> GST</Typography>
            <Typography> Total</Typography>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "right" }}>
            <Typography variant="body2">{subtotal}</Typography>
            <Typography variant="body2">
              {(subtotal * tax).toFixed(2)}
            </Typography>
            <Typography> {getTotal()}</Typography>
          </Grid>
          <Grid item xs={12}>
            {show && (
              <React.Fragment>
                <br />
                <hr />
                <PaymentCCButton onComplete={handleComplete} />
                <div style={{ height: 55 }}></div>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
