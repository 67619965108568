import React, { useEffect, useState } from "react";
import "js-cloudimage-360-view";
import Tridi from "react-tridi";
import "react-tridi/dist/index.css";

export default function Product360Video({ media }) {
  const [width, setWidth] = useState(null);
  //if (width === null) setWidth(window.innerWidth);

  //returns child element width to componsate scrollbar
  //from browswer
  const getWidth = () => {
    const w = document.getElementById("root").childNodes[0].childNodes[0]
      .clientWidth;
    //alert(w);
    return w;
  };

  useEffect(() => {
    window.CI360.init();
    window.addEventListener("resize", () => setWidth(getWidth()));
    //window.onresize = () => setTimeout(() => setWidth(getWidth()), 50);
    // console.log(
    //   "testwidth",
    //   document.getElementById("root").childNodes[0].childNodes[0].clientWidth
    // );
    return () => window.CI360.destroy();
  }, []);
  return (
    <div
      className="widthAnk"
      style={{
        borderBottom: "thin solid lightgray",
        borderTop: "thin solid lightgray",
        WebkitUserSelect: "none",
        userSelect: "none",
      }}
    >
      <div
        style={{
          width: width,
          height: "100%",
          display: "block",
          maxWidth: "100%",
          maxHeight: "100%",
          WebkitUserSelect: "none",
          userSelect: "none",
        }}
      >
        {/* <div
          className="cloudimage-360"
          data-folder={media.folder}
          data-filename={media.filename}
          data-amount={media.amount}
          data-spin-reverse
          data-autoplay //remove "&& !this.isMobile" from source
          speed={180}
        /> */}
        <Tridi
          style={{ WebkitUserSelect: "none", userSelect: "none" }}
          location={media.folder}
          count={media.amount}
          format="jpg"
          touch
          touchDragInterval={1}
          autoplay
          autoplaySpeed={80}
          stopAutoplayOnClick
          resumeAutoplayOnMouseLeave
        />
      </div>
    </div>
  );
}
