import React, { useEffect, useState } from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

SwiperCore.use([Pagination]);

/// position in place to solve fixed parent div issue
const BasicSlide = ({ images }) => {
  const [width, setWidth] = useState(null);
  const [active, setActive] = useState(0);
  const screenRatio = 0.75;
  const maxWidth = 600;

  const page = `${active + 1}/${images.length}`;
  const getWidth = (width) => {
    // add maxWidth to state
    if (width > maxWidth) return maxWidth;
    else return width;
  };

  if (width === null) setWidth(getWidth(window.innerWidth));
  useEffect(() => {
    //console.log("innerWidth", window.innerWidth);
    //console.log("innerDoc", document.innerWidth);
    window.addEventListener("resize", () =>
      setWidth(getWidth(window.innerWidth))
    );
  }, []);
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: width * screenRatio,
        overflow: "hidden",
        backgroundColor: "black",
        borderBottom: "thin solid lightgray",
        borderTop: "thin solid lightgray",
      }}
    >
      <div
        style={{
          position: "absolute",
          right: 15,
          top: 15,
          color: "lightgray",
          zIndex: 10,
        }}
      >
        {page}
      </div>
      <div
        style={{
          position: "absolute",
          width: width,
          height: width * screenRatio,
        }}
      >
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          onSlideChange={(e) => setActive(e.snapIndex)}
          onSwiper={(swiper) => null}
        >
          {images.map((img, index) => (
            <SwiperSlide key={index}>
              <img
                style={{
                  width: width,
                  height: width * screenRatio,
                  objectFit: "contain",
                  backgroundColor: "white",
                }}
                src={img.src}
                alt=""
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BasicSlide;
