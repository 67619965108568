import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { normalize, schema } from "normalizr";
import { selectPresentsById } from "./presentSlice";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

//normalize fetch datat
// Define a users schema
const commentsEnt = new schema.Entity("comments", {}, { idAttribute: "_id" });

// Define your comments schema
const presentsEnt = new schema.Entity(
  "presents",
  {
    comments: [commentsEnt],
  },
  { idAttribute: "_id" }
);
const clientEnt = new schema.Entity("client", {
  presents: [presentsEnt],
});

export const verifyClient = createAsyncThunk(
  "client/verifyClient",
  async (clientId, thunkAPI) => {
    const response = await axios({
      url: "/client/" + clientId,
      method: "get",
    });
    if (response.data === null)
      return thunkAPI.rejectWithValue(
        "Incorrect Client ID, contact your consultant"
      );
    else return normalize(response.data, clientEnt);
  }
);

export const purchaseComplete = createAsyncThunk(
  "client/purchaseComplete",
  async (details, thunkAPI) => {
    const state = thunkAPI.getState();
    const cartList = state.persist.cart.map((id) =>
      selectPresentsById(state, id)
    );
    const response = await axios({
      url: "/client/purchase",
      method: "post",
      data: {
        id: state.client.id,
        purchaseList: cartList,
        transactionDetails: details,
      },
    });
    if (response.data.status === "failed" || response.data === null)
      return thunkAPI.rejectWithValue("create new design failed");
    else return cartList;
  }
);
export const uploadImages = createAsyncThunk(
  "client/uploadImages",
  async (formData, thunkAPI) => {
    console.log(formData);
    const response = await axios({
      url: process.env.REACT_APP_API_ROOT_URL + "/pubpresent/upload",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
        "auth-token-pub": thunkAPI.getState().client.token,
      },
      data: formData,
    });
    if (response.data.status === "failed" || response.data === null)
      return thunkAPI.rejectWithValue("Failed at server");
    else return response.data;
  }
);

const slice = createSlice({
  name: "client",
  initialState: {
    id: null,
    session: [],
    connect: false,
    token: null,
    send: [], // {id: int, type: string, message: string, sent: boolean}
    imageLoading: false,
    maxVideo: true,
  },
  reducers: {
    assignDesign: (state, action) => {
      state.selectedDesign = action.payload;
    },
    setConnect: (state, action) => {
      state.connect = action.payload;
    },
    clearSession: (state, action) => {
      state.session = [];
      state.connect = false;
      state.token = null;
      state.maxVideo = true;
    },
    markSent: (state, action) => {
      state.send[action.payload].sent = true;
    },
    startImageLoading: (state, action) => {
      state.imageLoading = true;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setMaxVideo: (state, action) => {
      state.maxVideo = action.payload;
    },
  },
  extraReducers: {
    [verifyClient.fulfilled]: (state, action) => {
      //console.log("verifyClient: ", action.payload);
      state.id = action.payload.entities.client.undefined._id;
      state.session = action.payload.entities.client.undefined.session;
    },
    [uploadImages.fulfilled]: (state, action) => {
      state.send.push({
        id: 1001,
        type: 9,
        message: action.payload,
        sent: false,
      });
      state.imageLoading = false;
      state.token = null;
    },
    [uploadImages.rejected]: (state, action) => {
      state.imageLoading = false;
      alert("Upload Error"); //
    },
  },
});

const { actions, reducer } = slice;

export const {
  assignDesign,
  setConnect,
  clearSession,
  markSent,
  startImageLoading,
  setToken,
  setMaxVideo,
} = actions;

export const selClientId = createSelector(
  (state) => state.client,
  (client) => client.id
);

export default reducer;
