import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import clientSlice from "./ents/clientSlice";
import presentSlice from "./ents/presentSlice";
import commentSlice from "./ents/commentSlice";
import uiSlice from "./ents/uiSlice";
import persistSlice from "./ents/persistSlice"

const configAppStore = configureStore({
  reducer: {
    client: clientSlice,
    presents: presentSlice,
    comments: commentSlice,
    ui: uiSlice,
    persist: persistSlice,
  },
});

export default configAppStore;
