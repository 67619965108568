import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveView, activeViewUI } from "../store/ents/uiSlice";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import CreateIcon from "@material-ui/icons/Create";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { id as sendPhotoId } from "./SendPhoto";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    backgroundColor: "white",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    border: 10,
    zIndex: 9,
  },
});

export default function SimpleBottomNavigation({ show, disable }) {
  const dispatch = useDispatch();
  const isConnect = useSelector((state) => state.client.connect);
  const isSendPhoto = useSelector((state) => state.client.token);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
      style={{ opacity: disable ? 0 : 0.9 }}
    >
      <BottomNavigationAction
        onClick={() => dispatch(setActiveView(activeViewUI.presents))}
        label="Presents"
        icon={<CardGiftcardIcon label="Presents" />}
      />
      <BottomNavigationAction
        onClick={() => dispatch(setActiveView(activeViewUI.saved))}
        label="Favorites"
        icon={<FavoriteIcon />}
      />
      {isSendPhoto !== null && (
        <BottomNavigationAction
          onClick={() => document.getElementById(sendPhotoId).click()}
          label="Send"
          icon={<PhotoCameraIcon />}
        ></BottomNavigationAction>
      )}
      {isConnect && (
        <BottomNavigationAction
          onClick={() => dispatch(setActiveView(activeViewUI.design))}
          label="Design"
          icon={<CreateIcon />}
        />
      )}
      <BottomNavigationAction
        onClick={() => dispatch(setActiveView(activeViewUI.cart))}
        label="Cart"
        icon={<ShoppingCartIcon />}
      />
    </BottomNavigation>
  );
}
