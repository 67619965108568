import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { verifyClient } from "./clientSlice";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const statusUI = {
  idle: "idle",
  processing: "processing",
  verfied: "verifed",
  rejected: "rejected",
};
export const activeViewUI = {
  presents: "presents",
  saved: "saved",
  design: "design",
  cart: "cart",
};

export const slice = createSlice({
  name: "ui",
  initialState: { verifyStatus: statusUI.idle, activeView: "presents" },
  reducers: {
    setActiveView: (state, action) => {
      state.activeView = action.payload;
    },
  },
  extraReducers: {
    [verifyClient.pending]: (state, action) => {
      if (state.verifyStatus !== statusUI.verfied)
        state.verifyStatus = statusUI.processing;
    },
    [verifyClient.fulfilled]: (state, action) => {
      state.verifyStatus = statusUI.verfied;
    },
    [verifyClient.rejected]: (state, action) => {
      state.verifyStatus = statusUI.rejected;
    },
  },
});

const { actions, reducer } = slice;

export const { setActiveView } = actions;

export const selDesignList = createSelector(
  (state) => state.designs,
  (designs) => designs.images
);

export default reducer;
