import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { verifyClient, purchaseComplete } from "./clientSlice";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const presentsAdapter = createEntityAdapter({
  selectId: (present) => present._id,
});

const slice = createSlice({
  name: "presents",
  initialState: presentsAdapter.getInitialState(),
  reducers: {
    addPresent: (state, action) => {
      presentsAdapter.addOne(state, action.payload);
    },
    removePresent: (state, action) => {
      presentsAdapter.removeOne(state, action.payload._id);
    },
  },
  extraReducers: {
    [verifyClient.fulfilled]: (state, action) => {
      if (action.payload.entities.presents)
        presentsAdapter.setAll(state, action.payload.entities.presents);
    },
    [purchaseComplete.fulfilled]: (state, action) => {
      const list = action.payload;
      list.forEach((item) => {
        state.entities[item._id].valid.avail = false;
        state.entities[item._id].ui.purchased = true;
      });
    },
  },
});

const { actions, reducer } = slice;

export const { addPresent, removePresent } = actions;

export const {
  selectById: selectPresentsById,
  selectAll: selectAllPresents,
  selectIds: selectPresentsIds,
} = presentsAdapter.getSelectors((state) => state.presents);

export default reducer;
