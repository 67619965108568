import React from "react";
import { Button, Popup, Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { getVideoSrc, getDnaSrc } from "./_utility";
import { save, unsave, addCart, removeCart } from "../store/ents/persistSlice";
import Video360Seek from "./Video360Seek";
import { isValid } from "./Valid";

console.log("thisbuton");
const PresentButtonGroup = ({ item, types }) => {
  const dispatch = useDispatch();
  const saveList = useSelector((state) => state.persist.saved);
  const cartList = useSelector((state) => state.persist.cart);
  const inSaveList = saveList.includes(item._id);
  var inCartList = cartList.includes(item._id);

  // use variable to disable unvail items
  if (isValid(item) === false) inCartList = true;

  const buttons = {
    video: (
      <Button
        key={0}
        basic
        icon="video"
        onClick={() =>
          window.open(getVideoSrc(item.present.refID.toString()), "_blank")
        }
      />
    ),
    unsave: (
      <Button
        key={1}
        basic
        icon="close"
        onClick={() => dispatch(unsave(item))}
      />
    ),
    save: (
      <Button
        key={2}
        basic
        icon={inSaveList ? "heart" : "heart outline"}
        disabled={inSaveList}
        onClick={() => dispatch(save(item))}
      />
    ),
    addCart: (
      <Button
        key={3}
        basic
        icon="cart"
        disabled={inCartList}
        onClick={() => dispatch(addCart(item))}
      />
    ),
    removeCart: (
      <Button
        key={4}
        basic
        icon="close"
        onClick={() => dispatch(removeCart(item))}
      />
    ),
    labVideo: (
      <Modal key={7} closeIcon trigger={<Button basic icon="video" />}>
        <Modal.Content>
          <Video360Seek videos={item.present.videos} />
        </Modal.Content>
      </Modal>
    ),
    modalVideo: (
      <Modal
        key={8}
        size="fullscreen"
        closeIcon
        centered
        trigger={<Button basic icon="video" />}
      >
        <Modal.Content style={{ padding: 0 }}>
          <iframe
            style={{ display: "block", border: "none" }}
            width="100%"
            height="600px"
            src={
              item.present.refID && getVideoSrc(item.present)
            }
          />
        </Modal.Content>
      </Modal>
    ),
  };

  return (
    <Button.Group floated="right" size="large">
      {types.map((type) => buttons[type])}
    </Button.Group>
  );
};

export default PresentButtonGroup;
