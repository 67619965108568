import React from "react";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./useStyles";
import { Typography, Icon } from "@material-ui/core";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import TotalBar from "./TotalBar";

export default function PresentView(props) {
  const classes = useStyles();
  let heading;
  let icon;
  switch (props.view) {
    case "present":
      heading = "Presents";
      icon = <CardGiftcardIcon fontSize="large" />;
      break;
    case "saved":
      heading = "Saved";
      icon = <FavoriteBorderIcon fontSize="large" />;
      break;
    case "cart":
      heading = "Cart";
      icon = <ShoppingCartOutlinedIcon fontSize="large" />;
      break;
    default:
      break;
  }

  // useEffect(() => {
  //   window.scrollTo(0, window.localStorage.getItem("hk-scroll-" + props.view));
  //   return () => {
  //     window.localStorage.setItem("hk-scroll-" + props.view, window.scrollY);
  //   };
  // });
  return (
    <div
      className={classes.root}
      style={{
        // background: "#EFEFF4",
        background: "white",
        opacity: 1,
        width: "100%",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        overflowY: "auto",
        zIndex: props.show ? 2 : 1,
      }}
    >
      <div
        style={{
          margin: "auto",
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <div
          align="center"
          style={{
            height: "90px",
            margin: "auto",
            // background: "#efeff4",
            color: "rgba(0,0,0,0.8)",
            width: "100%",
            padding: 15,
            // marginBottom: 5,
            // boxShadow: "10px 10px 5px -10px rgba(0,0,0,0.75)",
            borderBottom: "1px ",
            borderBottomColor: "#3C3C43",
          }}
        >
          {icon}
          <Typography variant="h4">{heading}</Typography>
        </div>
        <div id={props.view + "-scroll-top"} />
        {props.children}
        <div id={props.view + "-scroll-bottom"} />
      </div>
      <div style={{ height: "54px" }} />
    </div>
  );
}
