import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { purchaseComplete } from "./clientSlice";

export const saveDiamond = createAsyncThunk(
  "savePersist/saveDiamond",
  async (payload, thunkAPI) => payload
);

const slice = createSlice({
  name: "persist",
  initialState: {
    saved: [],
    cart: [],
  },
  reducers: {
    save: (state, action) => {
      state.saved.push(action.payload._id);
    },
    unsave: (state, action) => {
      state.saved.splice(state.saved.indexOf(action.payload._id), 1);
    },
    addCart: (state, action) => {
      state.cart.push(action.payload._id);
    },
    removeCart: (state, action) => {
      state.cart.splice(state.cart.indexOf(action.payload._id), 1);
    },
  },
  extraReducers: {
    [saveDiamond.fulfilled]: (state, action) => {
      state.diamonds.push(addSaveDate(action.payload));
    },
    [purchaseComplete.fulfilled]: (state, action) => {
      state.cart = [];
    },
  },
});

//Utility method
export const addSaveDate = (item) => {
  var temp = { ...item };
  temp.saveDate = Date.now();
  return temp;
};

const { actions, reducer } = slice;

export const { save, unsave, addCart, removeCart } = actions;

export const selSaveDiamonds = createSelector(
  (state) => state.savePersist,
  (save) => save.diamonds
);

const persistConfig = {
  key: "root-present-client-app",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);
export default persistedReducer;
