import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import App from "./ClientApp";
import Typography from "@material-ui/core/Typography";
import { useTransition, animated } from "react-spring";
import { useDispatch, useSelector } from "react-redux";
import { verifyClient } from "../store/ents/clientSlice";
import { statusUI } from "../store/ents/uiSlice";

export default function ClientWelcome() {

  const { clientId } = useParams();
  const dispatch = useDispatch();
  const verifyStatus = useSelector((state) => state.ui.verifyStatus);
  var msg = "";

  switch (verifyStatus) {
    case statusUI.idle:
      msg = "";
      break;
    case statusUI.processing:
      msg = "verifying ...";
      break;
    case statusUI.verfied:
      msg = "";
      break;
    case statusUI.rejected:
      msg = "Unauthorized, contact your consultant";
      break;
    default:
      msg = "";
  }
  useEffect(() => {
    setTimeout(() => {
      dispatch(verifyClient(clientId));
    }, 1200);
  }, [clientId]);
  return (
    <>{verifyStatus === statusUI.verfied ? <App /> : <Logo msg={msg} />}</>
  );
}
export function Logo({ msg }) {
  const [show, set] = useState(false);
  const transitions = useTransition(show, null, {
    from: {
      position: "absolute",
      opacity: 0,
      width: "100%",
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  useEffect(() => {
    set(true);
    return () => set(false);
  }, []);
  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props}>
          <div style={{ marginTop: "200px" }}>
            <img
              src="/logo.png"
              alt="water"
              width="66%"
              style={{ margin: "auto", display: "block", width: "300px" }}
            />
            <center>
              <Typography>{msg}</Typography>
            </center>
          </div>
        </animated.div>
      )
  );
}
