import React, { useEffect, useRef } from "react";
import SendPhotoFab from "./SendPhotoFab";

export default function CanvasRec() {
  const canvas = useRef();
  const logo = useRef();

  //logo watermarking
  useEffect(() => {
    //console.log("effLogo", logo.current);
    //console.log("effcanvas", canvas.current);
    let ctx = canvas.current.getContext("2d");
    const x = canvas.current.width / 2 - logo.current.width / 2;
    const y = canvas.current.height / 2 - logo.current.height / 2;
    ctx.globalAlpha = 0.1;
    ctx.drawImage(logo.current, x, y);
    ctx.globalAlpha = 1;
    //console.log(`x:${x} y:${y}`);
    //console.log("ctx", ctx);
  },[]); // runing one doesn't always show THIS ALSO clears with clear

  // function drawAuto({ move, line, style, width }) {
  //   // document.getElementById("lineTo").innerHTML = `${line.x}, ${line.y}`;
  //   //let can = document.getElementById("reciver");
  //   let rec = canvas.current.getContext("2d");
  //   rec.beginPath();
  //   rec.moveTo(move.x, move.y);
  //   rec.lineTo(line.x, line.y);
  //   rec.strokeStyle = "black";
  //   rec.lineWidth = 1;
  //   rec.lineJoin = "round";
  //   rec.stroke();
  //   rec.closePath();
  //   //save to local
  // }

  return (
    <React.Fragment>
      <canvas
        ref={canvas}
        id="paint-receiver"
        width={window.innerWidth}
        height={window.innerHeight}
        style={{
          border: "1px solid black",
          position: "fixed",
          left: 0,
          top: 0,
          background: "lightgrey",
        }}
      >
        FallBack Content
      </canvas>
      <div style={{ display: "none" }}>
        <img
          ref={logo}
          //src="http://limgoldsmiths.ca/sig/logosig.png"
          src="/canvas-logo.png"
          alt="water"
          width="250px"
        />
      </div>
      {/* {channelStatus && <SendPhotoFab />} */}
    </React.Fragment>
  );
}
