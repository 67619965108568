import { useEffect } from "react";
import { clear, loadImage } from "./CanvasTrans";

export const messageType = {
  present: 1,
  bag: 2,
  ui: 3,
  paint: 4,
  recieved: 5,
  initial: 6,
  paintClear: 7,
  paintLoadImage: 8,
  clientImage: 9, //client notifying dash photos have been uploaded
  photoToken: 10,
  maxVideo: 11,
};

function drawAuto({ move, line, style, width, globalCompositeOperation }) {
  // document.getElementById("lineTo").innerHTML = `${line.x}, ${line.y}`;
  let can = document.getElementById("paint-receiver");
  let rec = can.getContext("2d");
  rec.globalCompositeOperation = globalCompositeOperation;
  rec.beginPath();
  rec.moveTo(move.x, move.y);
  rec.lineTo(line.x, line.y);
  rec.strokeStyle = style;
  rec.lineWidth = width;
  rec.lineJoin = "round";
  rec.stroke();
  rec.closePath();
  //save to local
  //localStorage.setItem("paint-local", can.toDataURL());
}

export function MessageControl({ channel, type, id, message }) {
  channel.onmessage = (event) => {
    const msg = JSON.parse(event.data);
    console.log("MessageControl", msg);
    switch (msg.type) {
      case messageType.present:
        message.present(msg.message);
        window.setTimeout(
          //delay for loading
          document.getElementById("present-scroll-top").scrollIntoView(),
          1000
        );
        break;
      case messageType.bag:
        break;
      case messageType.ui:
        break;
      case messageType.paint:
        console.log("Paint type", msg.message);
        drawAuto(msg.message);
        break;
      case messageType.recieved:
        break;
      case messageType.initial:
        break;
      case messageType.paintClear:
        clear("paint-receiver");
        break;
      case messageType.paintLoadImage:
        loadImage("paint-receiver", msg.message);
        break;
      case messageType.photoToken:
        message.photoToken(msg.message);
        break;
      case messageType.maxVideo:
        message.maxVideo(msg.message);
        break;
      default:
        console.log("error");
    }

    // usefulness of sending back received ???
    // if (msg.type !== messageType.paint || msg.type !== messageType.recieved) {
    //   channel.send(JSON.stringify({ id: msg.id, type: messageType.recieved }));
    // }
  };
}

//mesage is function callback to trigger
//id to receive message notification and possible ui
export default function useMessageControl({ channel, type, id, message }) {
  useEffect(() => {
    //test message **remove
    //const msg = JSON.parse(JSON.stringify(testMessage));
    //*** use addEventListener then subscriptions can be removed  */
    console.log("message", channel);
    channel.onmessage = (event) => {
      const msg = JSON.parse(event.data);
      switch (type) {
        case messageType.present:
          console.log("useMessageControl", msg.message.type);
          message(msg.message);
          // scroll to bottom
          break;
        case messageType.bag:
          break;
        case messageType.ui:
          break;
        case messageType.paint:
          message(msg.message);
          break;
        case messageType.recieved:
          if (msg.id === id) {
            message();
          }
          break;
        default:
          console.log("error");
      }
      channel.send(JSON.stringify({ id: msg.id, type: messageType.recieved }));
    };
  });
  return null;
}
