import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPresentsById } from "../store/ents/presentSlice";
import { Loader } from "semantic-ui-react";
import { presentUtil } from "./_utility";

const PaypalCCButton = ({
  clientId,
  amount,
  items,
  itemsTotal,
  taxRate,
  onComplete,
  onDecline,
  onError,
  ...props
}) => {
  // defaults
  const currency_code = "CAD";
  const src = `https://www.paypal.com/sdk/js?client-id=${clientId}&intent=authorize&currency=CAD&components=buttons,funding-eligibility&locale=en_CA`;

  const [showProgress, setProgress] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = src;
    document.body.appendChild(script);

    script.onload = () => {
      setProgress(false);
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: "Present Client Order",
                  amount: {
                    currency_code,
                    value: amount,
                    breakdown: {
                      item_total: {
                        currency_code,
                        value: itemsTotal,
                      },
                      shipping: {
                        currency_code,
                        value: 0,
                      },
                      tax_total: {
                        currency_code,
                        value: itemsTotal * taxRate,
                      },
                    },
                  },
                  items,
                },
              ],
            });
          },
          onApprove: (data, actions) => {
            return actions.order
              .authorize()
              .then((details) => {
                // declined
                // REASONS: bill address, card limit, issuer denied
                if (details.error === "INSTRUMENT_DECLINED") {
                  console.log("INSTRUMENT_DECLINED");
                  onDecline(details);
                  return actions.restart();
                }
                // on complete callback
                // unable to pass details to callback ***paypal error
                onComplete(details);
              })
              .catch((err) => {
                console.log("onApprove err:", err);
              });
          },
          onError: (err) => {
            onError(err);
            console.log("paypal onerror:", err);
          },
          style: {
            layout: "vertical",
            tageline: false,
          },
          fundingSource: window.paypal.FUNDING.CARD,
        })
        .render("#paypal-button-container");
    };
  }, [amount]);

  return (
    <React.Fragment>
      {showProgress && (
        <center>
          <Loader active inline />
        </center>
      )}
      <div id="paypal-button-container" />
    </React.Fragment>
  );
};

export const itemize = (sku, name, unitPrice, quantity) => {
  const limitChar = (str, len) => {
    if (str.length >= len) {
      return str.substr(0, len - 3) + "...";
    } else return str;
  };

  const currency_code = "CAD";
  return {
    sku: limitChar(sku, 127), // max 127 characters
    name: limitChar(name, 127),
    unit_amount: {
      currency_code,
      value: unitPrice,
    },
    quantity, // string
    description: "Undefined", // currently not showing in paypal console
  };
};

export default PaypalCCButton;
