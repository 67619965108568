import { Header, Image } from "semantic-ui-react";
import React from "react";
import Video360Seek from "./Video360Seek";
import Product360Video from "./Product360Video";
import BasicSlide from "./BasicSlide";
//import Available from "./Available";
//import PriceCheck from "./PriceCheck";

export const presentUtil = (present) => {
  var info = {
    media: "",
    header: "",
    desc: "",
    price: "",
    ppname: "undefined",
    ppsku: "undefined",
  };
  const {
    refID,
    shape,
    carat,
    color,
    clarity,
    cut,
    polish,
    sym,
    flour,
    diam,
    price,
  } = present;
  if (present.type === "diamond" || present.type === "Lab2")
    info = {
      ppname: `${present.shape} ${present.carat}ct ${present.clarity}/${present.color}`,
      ppsku: `DIA refID ${present.refID}`,
      media: (
        <Image
          src={`https://diaimage.s3.us-west-2.amazonaws.com/media/images/${present.refID}.jpg`}
        />
      ),
      image: `https://diaimage.s3.us-west-2.amazonaws.com/media/images/${present.refID}.jpg`,
      header: "Diamond",
      desc: `${present.shape} ${present.carat}ct ${present.clarity}/${present.color}`,
      desc2: `${present.cut}/${present.polish}/${present.sym}`,
      desc3: `${present.diam} mm`,
      price: `${present.price}`,
      line: (
        <div>
          <div style={{ color: "darkgray", fontSize: "smaller" }}>{refID}</div>
          <div>{shape}</div>
          <div>{carat} ct</div>
          <div>{clarity}</div>
          <div>{color}</div>
          <div>
            {cut}/{polish}/{sym}
          </div>
          <div>Flour: {flour}</div>
          <div>
            {present.saveDate ? (
              <>
                {/* <PriceCheck refId={refID} presentPrice={price}></PriceCheck>
                <Available value="" refId={present.refID} /> */}
              </>
            ) : (
              price
            )}
          </div>
          {present.saveDate && (
            <div style={{ color: "darkgray", fontSize: "smaller" }}>
              {formatDate(present.saveDate)}
            </div>
          )}
        </div>
      ),
      buttonTypes: ["modalVideo", "addCart"],
    };
  if (present.type === "Lab2") {
    info.media = <Image src={present.image} />;
    info.image = present.image;
  }
  if (present.type === "lab")
    info = {
      ppname: `${present.shape} ${present.carat}ct ${present.clarity}/${present.color}`,
      ppsku: `LAB refID ${present.refID}`,
      media: <Video360Seek videos={present.videos} />,
      image: present.images.full,
      header: "Lab Diamond",
      desc: `${present.shape} ${present.carat}ct ${present.clarity}/${present.color}`,
      desc2: `${present.cut}/${present.polish}/${present.sym}`,
      desc3: `${present.diam} mm`,
      price: present.price,
      line: (
        <div>
          <div style={{ color: "darkgray", fontSize: "smaller" }}>{refID}</div>
          <div>{shape}</div>
          <div>{carat} ct</div>
          <div>{clarity}</div>
          <div>{color}</div>
          <div>
            {cut}/{polish}/{sym}
          </div>
          <div>Flour: {flour}</div>
          <div>{price}</div>
          {present.saveDate && (
            <div style={{ color: "darkgray", fontSize: "smaller" }}>
              {formatDate(present.saveDate)}
            </div>
          )}
        </div>
      ),
      buttonTypes: ["labVideo", "addCart"],
    };
  if (present.type === "product360")
    info = {
      image: "https://limgoldsmith.ca/test/360/_0001.jpg",
      header: "Product360",
      desc: present.name,
      price: present.price,
    };
  if (
    present.type === "recents" ||
    present.type === "product" ||
    present.type === "custom"
  ) {
    var recMedia;
    if (present.media[1].type === "360")
      recMedia = <Product360Video media={present.media[1]} />;
    if (present.media.length > 1) {
      var images = present.media.filter((img) => img.type === "image");
      if (images.length === 1) recMedia = <Image src={images[0].src} />;
      if (images.length > 1) recMedia = <BasicSlide images={images} />;
    }
    info = {
      ppname: `${present.desc}`,
      ppsku: `${present.name} ID ${present._id}`,
      image: present.media[0].src,
      media: recMedia,
      header: "Recent Work",
      desc: present.name,
      desc2: present.desc,
      price: present.price,
      buttonTypes: present.price === null ? [] : ["addCart"],
    };
    const any = present.media.find((med) => med.type === "360");
    if (any) {
      info.image = any.thumb;
      info.is360 = true;
    }
    if (present.type === "product") info.header = "Product";
    if (present.type === "custom") info.header = "Custom";
  }
  //console.log(info);
  return info;
};

export const formatDate = (date) => {
  const event = new Date(date);
  const options = { month: "short", day: "numeric", year: "numeric" };
  const optionsTime = { hour: "numeric", minute: "numeric" };

  return `${event.toLocaleDateString(
    undefined,
    options
  )}, ${event.toLocaleTimeString(undefined, optionsTime)}`;
};
export const getVideoSrc = (present) => {
  var video;
  var refId = present.refID.toString();
  if (refId.length === 9)
    //jb
    //video = `https://dna.stonehdfile.com/ViewHD.html?d=${refId}`;
    video = present.video;
  if (refId.length === 10)
    if (refId.includes("LAB")) {
      video = present.link;
    } else {
      video = `https://media.hdfiles.in:8520/HD4//Vision360.html?d=${refId}`;
    }
  if (present.type === "Lab2") video = present.link;

  //video = present.link;
  return video;
};
export const getDnaSrc = (refId) => {
  var dna;
  if (refId.length === 9)
    dna = `https://i.stonehdfile.com/DNAReport?RefNo=${refId}`;
  if (refId.length === 10)
    dna = `https://pck2.azureedge.net/stone-multimedia/stone-multimedia.htm?stoneId=${refId}&showMediaType=Digiplott`;
  return dna;
};
export const limitChar = (str, len) => {
  if (str.length >= len) {
    return str.substr(0, len - 3) + "...";
  } else return str;
};
