import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0)
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    marginBottom: 10
  },
  img: {
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  info: {
    padding: theme.spacing(1, 2),
    textAlign: "left"
  },
  iconGroup: {
    padding: theme.spacing(0)
  },
  modal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 0, 0),
    position: "absolute",
    border: "2px solid #000",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(4),
    zIndex: 10
  }
}));
