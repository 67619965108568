import React, { useState, useEffect } from "react";
import "./styles.css";
import PresentView from "./PresentView";
import SimpleBottomNavigation from "./SimpleBottomNavigation";
import CanvasRec from "./CanvasRec";
import VideoDisplay from "./VideoDisplay";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllPresents,
  selectPresentsById,
  selectPresentsIds,
} from "../store/ents/presentSlice";
import { verifyClient } from "../store/ents/clientSlice";
import { activeViewUI } from "../store/ents/uiSlice";
import TotalBar from "./TotalBar";
import PresentList from "./PresentList";
import CartList from "./CartList";
import { Divider } from "semantic-ui-react";
import Connection from "./Connection";
import SendPhoto from "./SendPhoto";
import { removeCart, unsave } from "../store/ents/persistSlice";

export const ConnectionContext = React.createContext("connection");

export default function ClientApp() {
  const session = useSelector((state) => state.client.session);
  const isConnect = useSelector((state) => state.client.connect);
  const presents = useSelector((state) => selectAllPresents(state));
  const clientId = useSelector((state) => state.client.id);
  const presentIds = useSelector(selectPresentsIds);
  const saveIds = useSelector((state) => state.persist.saved);
  const cartIds = useSelector((state) => state.persist.cart);
  const savedList = useSelector((state) =>
    state.persist.saved.map((id) => selectPresentsById(state, id))
  );
  const activeView = useSelector((state) => state.ui.activeView);
  const dispatch = useDispatch();

  //clear save & cart ids
  cartIds.forEach((id) => {
    if (!presentIds.includes(id)) dispatch(removeCart(id));
  });
  saveIds.forEach((id) => {
    if (!presentIds.includes(id)) dispatch(unsave(id));
  });

  //order present new at top
  var revpresents = [...presents];
  revpresents = revpresents.reverse();

  useEffect(() => {
    document.onvisibilitychange = () => {
      //check for connection to not fetch to server
      if (document.hidden === false && isConnect === false) {
        dispatch(verifyClient(clientId));
      }
    };
  });

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100%",
        width: "100%",
        maxWidth: "599px",
      }}
    >
      <Connection />
      <PresentView
        view="present"
        show={activeView === activeViewUI.presents ? true : false}
      >
        <PresentList list={revpresents} />
      </PresentView>
      <PresentView
        view="saved"
        show={activeView === activeViewUI.saved ? true : false}
      >
        <PresentList list={savedList} saved />
      </PresentView>
      <PresentView
        view="design"
        show={activeView === activeViewUI.design ? true : false}
      >
        {/* hide canvas from html view/code */}
        {session.length > 0 && <CanvasRec />}
      </PresentView>
      <PresentView
        view="cart"
        show={activeView === activeViewUI.cart ? true : false}
      >
        <Divider style={{ width: "100%", marginBottom: 0 }} />
        <CartList />
        <TotalBar />
        <div style={{ height: "100px", width: "100%" }} />
      </PresentView>

      {session.length > 0 && <VideoDisplay />}
      {session.length > 0 && <SendPhoto />}
      <SimpleBottomNavigation />
    </div>
  );
}
