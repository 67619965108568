import { red } from "@material-ui/core/colors";
import React from "react";
import { formatDate } from "./_utility";

const Valid = ({ item }) => {
  var message = "";
  var style = { fontSize: "small" };

  if (item.ui.purchased) {
    message = "Purchased";
    style = { color: "red" };
  } else {
    if (item.valid.avail === false) {
      message = "SOLD";
      style = { color: "red" };
    } else {
      const validDate = new Date(item.valid.expires);
      const currentDate = Date.now();
      style = { color: "grey" };
      if (validDate < currentDate) {
        message = "Offer expired";
        style = { color: "red" };
      } else {
        message = `Valid until ${formatDate(validDate)}`;
      }
    }
  }
  return <span style={style}>{message}</span>;
};

export const isValid = (item) => {
  var valid = true;
  if (
    item.ui.purchased === true ||
    item.valid.avail === false ||
    new Date(item.valid.expires) < Date.now()
  )
    valid = false;

  return valid;
};

export default Valid;
