import { useEffect, useState } from "react";

//Custom Hook create to connect to WebRTC using websocket to signal
//required username to identify parties in signaling
//initiated connections and waiting/lisitng connections
//would like to also pass in boolean to create DataChannel
//data channel currently created***
//session is an array - single array
export default function useConnection({
  session,
  from,
  initiate,
  setConStatus,
}) {
  const [statePeerConnection, setStatePeerConnection] = useState(null);

  useEffect(() => {
    //no session crated
    if (session.length === 0) {
      console.log("useConnection: session.length === 0");
      return;
    }
    //initalizing variables from session
    console.log("session:", session[0].client);
    let username;
    let targetUsername;
    if (from === "client") {
      username = session[0].client;
      targetUsername = session[0].dash;
    } else {
      username = session[0].dash;
      targetUsername = session[0].client;
    }
    const server = session[0].signal;
    const token = session[0].token;

    var myPeerConnection;
    var myDataChannel;
    const connection = new WebSocket(server + "/w=1&token=" + token, "json");
    connection.onerror = (e) => {
      console.log("WSConnection error: " + e);
    };
    connection.onopen = (e) => {
      //Start connection process
      console.log("WSconnection: Open");
      connection.onmessage = handleWSMessage;
      createPeerConnection();
    };
    connection.onclose = (e) => console.log("WSConnection: Close");
    //START Functions
    var localstream;
    async function createPeerConnection() {
      // const config = {
      //   iceServers: [
      //     // Information about ICE servers - Use your own!
      //     // add more stun servers and setup turn server
      //     {
      //       urls: "stun:stun.stunprotocol.org",
      //     },
      //     { urls: "stun:stun.l.google.com:19302" },
      //     { urls: "stun:stun1.l.google.com:19302" },
      //     { urls: "stun:stun2.l.google.com:19302" },
      //     { urls: "stun:stun3.l.google.com:19302" },
      //   ],
      // };
      const config = {
        iceServers: [
          {
            urls: process.env.REACT_APP_STUN_SERVER,
          },
          {
            urls: process.env.REACT_APP_TURN_SERVER,
            username: process.env.REACT_APP_TURN_USERNAME,
            credential: process.env.REACT_APP_TURN_CRED,
          },
        ],
      };
      const mediaConstraints = {
        audio: true, // We want an audio track
        video: true, // ...and we want a video track
      };

      myPeerConnection = new RTCPeerConnection(config);
      localstream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
      localstream.getTracks().forEach((track) => {
        myPeerConnection.addTrack(track, localstream);
      });
      // set local stream to video element
      document.getElementById("local_video").srcObject = localstream;

      // two ends initator and waiter
      if (initiate) {
        myDataChannel = myPeerConnection.createDataChannel("MessageChannel");
        const offer = await myPeerConnection.createOffer();
        console.log("CreateDataChannel");
        // onopen doest no work with safari
        myDataChannel.onopen = (event) =>
          console.log("DataChannel Open from onDatatChannel - initiate");
        myDataChannel.onmessage = (event) =>
          console.log("Message Received CreateDatatChanel");

        await myPeerConnection
          .setLocalDescription(offer)
          .catch((err) =>
            console.log("Error createOffer.setLocalDescription: " + err)
          );
        sendToServer({
          name: username,
          target: targetUsername,
          type: "video-offer",
          sdp: offer,
        });
      }

      //Listen for locale ICE canidates on

      //myPeerConnection.onconnectionstatechange = handleOnconnectionstatechange;
      myPeerConnection.addEventListener("icecandidate", (event) => {
        sendToServer({
          type: "new-ice-candidate",
          target: targetUsername,
          candidate: event.candidate,
        });
      });
      myPeerConnection.addEventListener("iceconnectionstatechange", (event) => {
        console.log(
          "ICE Connection State: " + myPeerConnection.iceConnectionState
        );
        if (myPeerConnection.iceConnectionState === "connected") {
          setStatePeerConnection({
            connection: myPeerConnection,
            channel: myDataChannel,
          });
          setConStatus(true);
        }
      });

      myPeerConnection.addEventListener("track", async (event) => {
        console.log("track EventLIstner");
        //*** addtrack doesn't work on safari */
        //remotestream.addTrack(event.track, remotestream);
        document.getElementById("received_video").srcObject = event.streams[0];
      });

      //This is working Safari
      // receiver will be called here
      myPeerConnection.ondatachannel = (event) => {
        myDataChannel = event.channel;
        myDataChannel.onopen = (event) =>
          console.log("DataChannel Open from onDatatChannel");
        setStatePeerConnection({
          connection: myPeerConnection,
          channel: myDataChannel,
        });
        setConStatus(true);
        myDataChannel.onmessage = (event) => console.log("Message Received");
      };
      /*
      // Safari does not support connectionState property
      //Connection Complete
      myPeerConnection.addEventListener("connectionstatechange", event => {
        if (myPeerConnection.connectionState === "connected") {
          console.log("PeerConnection: connected");
          setStatePeerConnection(myPeerConnection);
        }
      }); */
    }
    function handleWSMessage(e) {
      var msg = JSON.parse(e.data);
      console.log("Message received: ", msg);
      console.dir(msg);

      switch (msg.type) {
        //register username - initaled on connection to websocket
        case "id":
          sendToServer({
            name: username,
            date: Date.now(),
            id: msg.id,
            type: "username",
          });
          break;
        // Signaling messages: these messages are used to trade WebRTC
        // signaling information during negotiations leading up to a video
        // call.

        case "video-offer": // Invitation and offer to chat
          handleVideoOfferMsg(msg);
          console.log(msg);
          break;

        case "video-answer": // Callee has answered our offer
          handleVideoAnswerMsg(msg);
          console.log(msg);
          break;

        case "new-ice-candidate": // A new ICE candidate has been received
          handleNewICECandidateMsg(msg);
          console.log(msg);
          break;

        case "hang-up": // The other peer has hung up the call
          //handleHangUpMsg(msg);
          console.log("hang-up Called in WBonMessage");
          break;

        // Unknown message; output to console for debugging.
        default:
          //user list msg before connection
          //id msg on connection
          console.log("Unknown message received:");
          console.log(msg);
      }
    }
    function sendToServer(msg) {
      var msgJSON = JSON.stringify(msg);
      connection.send(msgJSON);
    }
    async function handleVideoAnswerMsg(msg) {
      console.log("*** Call recipient has accepted our call");
      const remoteDesc = new RTCSessionDescription(msg.sdp);
      await myPeerConnection
        .setRemoteDescription(remoteDesc)
        .catch((err) => console.log("handleVideoAnswerMsg" + err));
    }
    async function handleVideoOfferMsg(msg) {
      myPeerConnection.setRemoteDescription(new RTCSessionDescription(msg.sdp));
      const answer = await myPeerConnection.createAnswer();
      await myPeerConnection
        .setLocalDescription(answer)
        .catch((err) => console.log("handleVideoOfferMsg" + err));
      sendToServer({
        name: username,
        target: targetUsername,
        type: "video-answer",
        sdp: answer,
      });
    }
    async function handleNewICECandidateMsg(msg) {
      await myPeerConnection
        .addIceCandidate(msg.candidate)
        .catch((err) => console.log("handleNewiceCandidateMsg" + err));
    }

    const cleanup = () => {
      console.log("Cleanup");
      connection.close();
      if (myDataChannel) myDataChannel.close();
      if (myPeerConnection) myPeerConnection.close();
      if (localstream) localstream.getTracks().forEach((track) => track.stop());
      // these are now unmounted visually
      // document.getElementById("local_video").srcObject = null;
      // document.getElementById("received_video").srcObject = null;
    };

    return cleanup;
  }, [session]);
  return statePeerConnection;
}
