import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Comment, Button, Form, Icon, Header } from "semantic-ui-react";
import { selectCommentsById } from "../store/ents/commentSlice";
import { formatDate } from "./_utility";

const PresentComments = ({ list, present }) => {
  const [formOpen, setFormOpen] = useState(true);
  const [value, setValue] = useState("");

  const coms = useSelector((state) =>
    list.map((id) => selectCommentsById(state, id))
  );

  const closeForm = () => {
    setFormOpen(true);
    setValue("");
  };
  const handleChange = (e, { value }) => {
    setValue(value);
  };

  return (
    <Comment.Group>
      {list.length > 0 && <Header as="h5">Comments</Header>}
      {list.length > 0 &&
        coms.map((com) => (
          <Comment key={com._id}>
            <Comment.Avatar
              size="mini"
              src="https://hypebeast.com/image/2020/08/metropolitan-museum-of-art-kidrobot-dunny-collection-info-2.jpg"
            ></Comment.Avatar>
            <Comment.Content>
              <Comment.Author as="a">{com.by.name}</Comment.Author>
              <Comment.Metadata>
                <span>{formatDate(com.date)}</span>
              </Comment.Metadata>
              <Comment.Text>{com.comment}</Comment.Text>
            </Comment.Content>
          </Comment>
        ))}
    </Comment.Group>
  );
};

export default PresentComments;
