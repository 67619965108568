import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectPresentsById } from "../store/ents/presentSlice";
import { purchaseComplete } from "../store/ents/clientSlice";
import PaypalCCButton, { itemize } from "./PaypalCCButton";
import { presentUtil } from "./_utility";

const PaymentCCButton = ({ onComplete }) => {
  /////////
  const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID_TEST;
  ////////
  const dispatch = useDispatch();
  const cartList = useSelector((state) =>
    state.persist.cart.map((id) => selectPresentsById(state, id))
  );

  const taxRate = 0.05;
  var items = [];
  var itemsTotal = 0;

  cartList.forEach((e) => {
    const { ppname, ppsku } = presentUtil(e.present);
    items.push(itemize(ppsku, ppname, e.present.price, "1"));
    itemsTotal += e.present.price;
  });

  const handleComplete = (details) => {
    dispatch(purchaseComplete(details));
    const temp = {
      name: details.payer.name.given_name,
      amount: details.purchase_units[0].amount.value,
    };
    onComplete(temp);
  };
  const handleDecline = (details) => {
    alert(
      "Transcation DECLINED.  Please check your billing info is entered correctly. Lastly, for LARGE PURCHASES you may need to call your credit card company to clear the transaction."
    );
  };
  const handleError = (err) => {
    alert(
      "An unknown error occured. Please refresh the browser and try again, or contact Lim Goldsmiths."
    );
  };

  return (
    <PaypalCCButton
      clientId={clientId}
      amount={itemsTotal * taxRate + itemsTotal}
      items={items}
      itemsTotal={itemsTotal}
      taxRate={taxRate}
      onComplete={handleComplete}
      onDecline={handleDecline}
      onError={handleError}
    />
  );
};

export default PaymentCCButton;
