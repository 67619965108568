import React, { useRef, useEffect } from "react";

export default function Video360Seek({ videos }) {
  const vid = useRef();
  useEffect(() => {
    const sfactor = 2000;
    let from = 0;

    //let direction;
    //vid.current.load();
    vid.current.addEventListener("touchend", () => vid.current.play(), false);
    vid.current.addEventListener(
      "touchstart",
      (ev) => {
        from = parseFloat(ev.touches[0].screenX);
        vid.current.pause();
      },
      false
    );
    vid.current.addEventListener(
      "touchmove",
      (ev) => {
        if (parseFloat(ev.touches[0].screenX) > from) {
          //direction = "right";
          const tp =
            vid.current.currentTime -
            parseFloat(ev.touches[0].screenX) / sfactor;
          //loop back to end
          if (tp < 0) {
            vid.current.currentTime = vid.current.duration - 0.001; //get off from duration
          } else {
            vid.current.currentTime = tp;
          }
          from = parseFloat(ev.touches[0].screenX);
        } else {
          if (parseFloat(ev.touches[0].screenX) < from) {
            //direction = "left";
            vid.current.currentTime =
              vid.current.currentTime +
              parseFloat(ev.touches[0].screenX) / sfactor;
            from = parseFloat(ev.touches[0].screenX);
          }
        }
        // temp.current.innerHTML = `Current: ${
        //   vid.current.currentTime
        // }<br> start: ${start} <br>screenZ: ${(start -
        //   parseFloat(ev.touches[0].screenX)) /
        //   100} <br>duration:${
        //   vid.current.duration
        // } <br>direction: ${direction}<br>`;
      },
      false
    );
    //console.log("video360 Seek", vid.current);
  }, []);

  return (
    <div>
      <img
        style={{
          position: "relative",
          top: "40px",
          left: "15px",
          WebkitUserSelect: "none",
        }}
        src="https://meteor.stullercloud.com/das/58025245?fmt=smart-alpha"
        alt="360"
      />
      <video
        width="100%"
        ref={vid}
        preload="auto"
        playsInline
        loop={true}
        autoPlay={true}
        style={{ WebkitUserSelect: "none" }}
      >
        <source src={videos.mp4} type="video/mp4" />
        <source src={videos.webm} type="video/webm" />
      </video>
    </div>
  );
}
