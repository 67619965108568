import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPresentsById,
  selectPresentsIds,
} from "../store/ents/presentSlice";
import { Item, Button } from "semantic-ui-react";
import { presentUtil } from "./_utility";
import PresentButtonGroup from "./PresentButtonGroup";
import { removeCart } from "../store/ents/persistSlice";
import { isValid } from "./Valid";

const CartList = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) =>
    state.persist.cart.map((id) => selectPresentsById(state, id))
  );
  return (
    <Item.Group
      divided
      unstackable
      style={{
        width: "100%",
        height: "100$",
        paddingLeft: "7px",
        paddingRight: "7px",
      }}
    >
      {list.map((item) => {
        //console.log("items", list);
        const { header, desc, desc2, desc3, price, image } = presentUtil(
          item.present
        );
        if (isValid(item))
          // check for valid
          return (
            <Item key={item._id}>
              <Item.Image src={image} />
              <Item.Content>
                <Item.Header style={{ fontSize: "1.19em" }}>{desc}</Item.Header>
                <Item.Meta>{header}</Item.Meta>
                <Item.Description style={{ marginTop: "0px" }}>
                  {desc2}
                </Item.Description>
                <Item.Description style={{ marginTop: "0px" }}>
                  {desc3}
                </Item.Description>
                <Item.Description
                  style={{
                    marginTop: "0px",
                    textAlign: "left",
                    paddingRight: 15,
                  }}
                >
                  {price}
                </Item.Description>
                <Item.Extra>
                  <div
                    style={{ maring: 0, width: "60px" }}
                    onClick={() => dispatch(removeCart(item))}
                  >
                    remove
                  </div>
                </Item.Extra>
              </Item.Content>
            </Item>
          );
      })}
    </Item.Group>
  );
};

export default CartList;
