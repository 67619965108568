import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllPresents } from "../store/ents/presentSlice";
import { Grid, Image, Button, Divider } from "semantic-ui-react";
import PresentComments from "./PresentComments";
import { presentUtil, formatDate } from "./_utility";
import { save } from "../store/ents/persistSlice";
import PresentButtonGroup from "./PresentButtonGroup";
import Valid from "./Valid";

const PresentList = ({ list, saved }) => {
  const dispatch = useDispatch();
  //const list = useSelector(selectAllPresents);
  //console.log(list);
  return (
    <Grid columns={1} style={{ margin: 0 }}>
      {list.map((item, index) => {
        const {
          header,
          desc,
          desc2,
          desc3,
          price,
          media,
          buttonTypes,
        } = presentUtil(item.present);
        var types = [];
        if (saved) types = ["unsave"];
        else types = ["save"];
        types.push(...buttonTypes);

        return (
          <Grid.Column style={{ padding: 0 }} key={index}>
            <Divider />
            <div style={{ paddingRight: "10px", paddingBottom: "5px" }}>
              <div style={{ textAlign: "right" }}>{header}</div>
              <div style={{ textAlign: "right" }}>
                <Valid item={item} />
              </div>
            </div>
            {media}
            <div style={{ padding: "15px" }}>
              <PresentButtonGroup item={item} types={types} />
              {/* <Button.Group floated="right" size="large">
                <Button
                  basic
                  icon="heart"
                  onClick={() => dispatch(save(item))}
                />
                <Button basic icon="coffee" />
                <Button basic icon="cart" />
              </Button.Group> */}

              <div
                style={{
                  fontSize: "medium",
                  fontWeight: "bold",
                  paddingBottom: "5px",
                }}
              >
                {desc}
              </div>
              <div>{desc2}</div>
              <div>{desc3}</div>
              {price && (
                <div style={{ fontSize: "medium", paddingTop: "5px" }}>
                  {price} <span style={{ fontSize: "x-small" }}>CAD</span>
                </div>
              )}

              <PresentComments list={item.comments} present={item._id} />
            </div>
          </Grid.Column>
        );
      })}
    </Grid>
  );
};

export default PresentList;
