import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { verifyClient } from "./clientSlice";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const commentsAdapter = createEntityAdapter({
  selectId: (present) => present._id,
});

export const slice = createSlice({
  name: "comments",
  initialState: commentsAdapter.getInitialState(),
  reducers: {
    assignDesign: (state, action) => {
      state.selectedDesign = action.payload;
    },
  },
  extraReducers: {
    [verifyClient.fulfilled]: (state, action) => {
      if (action.payload.entities.presents && action.payload.entities.comments)
        commentsAdapter.upsertMany(state, action.payload.entities.comments);
    },
  },
});

const { actions, reducer } = slice;

export const { assignDesign } = actions;

export const {
  selectById: selectCommentsById,
  selectAll: selectAllComments,
} = commentsAdapter.getSelectors((state) => state.comments);

export const selDesignList = createSelector(
  (state) => state.designs,
  (designs) => designs.images
);
export const selSelectedDesign = createSelector(
  (state) => state.designs,
  (des) => des.selectedDesign
);

export default reducer;
