import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";
import { useSpring, animated } from "react-spring";
import { setMaxVideo } from "../store/ents/clientSlice";

export default function VideoDisplay() {
  const isMaxVideo = useSelector((state) => state.client.maxVideo);
  const min = !isMaxVideo;
  const dispatch = useDispatch();

  //temp
  const handleClick = () => {
    dispatch(setMaxVideo(min));
  };

  const [dim, setDim] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const received_props = useSpring({
    position: "fixed",
    left: min ? 5 : 0,
    top: min ? 5 : 0,
    width: min ? "130px" : dim.width + "px",
    height: min ? "130px" : dim.height + "px",
    zIndex: 11,
    objectFit: "cover",
    borderRadius: min ? 65 : 0,
    backgroundColor: "#C6C6C8",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    opacity: min ? 0.92 : 1,
  });
  const local_props = useSpring({
    position: "fixed",
    width: "130px",
    height: "150px",
    right: 5,
    top: 5,
    zIndex: min ? 0 : 11,
    objectFit: "cover",
    borderRadius: 5,
    backgroundColor: "#C6C6C8",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    opacity: min ? 0 : 0.92,
  });
  useEffect(() => {
    window.onresize = () =>
      setDim({ width: window.innerWidth, height: window.innerHeight });
  });
  return (
    <div className="center">
      <center>
        <animated.div style={received_props}>
          <video
            className="round"
            id="received_video"
            autoPlay
            playsInline
            style={{
              objectFit: "cover",
              borderRadius: "inherit",
              boxShadow: "inherit",
            }}
            height="100%"
            width="100%"
            onClick={handleClick}
          />
        </animated.div>
        <animated.div style={local_props}>
          <video
            onClick={handleClick}
            className="round"
            id="local_video"
            autoPlay
            playsInline
            muted
            height="100%"
            width="100%"
            style={{
              objectFit: "cover",
              borderRadius: "inherit",
              boxShadow: "inherit",
            }}
          />
        </animated.div>
      </center>
    </div>
  );
}
