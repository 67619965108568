import React, { useEffect, useState } from "react";
import useCanvas from "./useCanvas";
import { messageType } from "./useMessageControl";

export function drawGrid(id) {
  // Box width
  var bw = 500;
  // Box height
  var bh = 600;
  // Padding
  var p = 0;
  var size = 30;
  const canvas = document.getElementById(id);
  var ctx = canvas.getContext("2d");
  for (var x = 0; x <= bw; x += size) {
    ctx.moveTo(0.5 + x + p, p);
    ctx.lineTo(0.5 + x + p, bh + p);
  }
  for (x = 0; x <= bh; x += size) {
    ctx.moveTo(p, 0.5 + x + p);
    ctx.lineTo(bw + p, 0.5 + x + p);
  }
  ctx.strokeStyle = "	#F8F8F8";
  ctx.lineWidth = 0.9;
  ctx.stroke();
}
export function clear(id) {
  const canvas = document.getElementById(id);
  canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
  localStorage.setItem("paint-local", null);
}

export function loadImage(id, URL) {
  const canvas = document.getElementById(id);
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.crossOrigin = "anonymous";
  img.src = URL;
  img.onload = function () {
    ctx.drawImage(img, 0, 0);
  };
}

export function loadFromLocalStorage() {
  const canvas = document.getElementById("canvas");
  var ctx = canvas.getContext("2d");
  //load local storage
  if (localStorage.getItem("paint-local") !== null) {
    var dataURL = localStorage.getItem("paint-local");
    var img = new Image();
    img.src = dataURL;
    img.onload = function () {
      ctx.drawImage(img, 0, 0);
    };
  }
}

export default function CanvasTrans({ channel, showView }) {
  const [selected, setSelected] = useState("Pencil");
  const [drawstyle, setstyle] = useState({
    lineWidth: 1,
    strokeStyle: "black",
    globalCompositeOperation: "source-over",
  });

  function handlePencil(e) {
    setSelected(e.currentTarget.innerText);
    if (e.currentTarget.innerText === "Pencil") {
      setstyle({
        lineWidth: 1,
        strokeStyle: "black",
        globalCompositeOperation: "source-over",
      });
    }
    if (e.currentTarget.innerText === "Eraser") {
      setstyle({
        lineWidth: 10,
        strokeStyle: "rgba(255,255,255,255)",
        globalCompositeOperation: "destination-out",
      });
    }
    if (e.currentTarget.innerText === "Draft") {
      setstyle({
        lineWidth: 1,
        strokeStyle: "#F5F5F5",
        globalCompositeOperation: "source-over",
      });
    }
  }

  function handleClear() {
    clear("canvas");
    if (channel) {
      channel.send(JSON.stringify({ id: 1001, type: messageType.paintClear }));
    }
  }
  useEffect(() => drawGrid("canvas-grid-lines"), []);
  useEffect(() => loadFromLocalStorage(), []);
  useCanvas({
    canvasId: "canvas",
    sender: true,
    channel: channel,
    drawStyle: drawstyle,
  });
  return (
    <div className="draw-container">
      <div style={{ position: "relative" }}>
        <canvas
          id="canvas"
          width="500"
          height="600px"
          style={{
            border: "1px solid grey",
            position: "absolute",
            zIndex: 1,
            right: 0,
          }}
        >
          FallBack Content
        </canvas>
        <canvas
          id="canvas-grid-lines"
          width="500"
          height="600px"
          style={{
            border: "1px solid grey",
            position: "absolute",
            zIndex: 0,
            right: 0,
          }}
        >
          FallBack Content
        </canvas>
        <label className="pencil-label">{selected}</label>
      </div>

      <div className="draw-col">
        <div>
          <button className="draw-sm-btn">&lt;</button>
          <button className="draw-sm-btn">&gt;</button>
        </div>
        <button className="draw-btn" onClick={handlePencil}>
          Pencil
        </button>
        <button className="draw-btn" onClick={handlePencil}>
          Draft
        </button>
        <button className="draw-btn" onClick={handlePencil}>
          Eraser
        </button>
        <button className="draw-btn" onClick={handleClear}>
          Clear
        </button>
        <button className="draw-btn">Save</button>
      </div>
    </div>
  );
}
