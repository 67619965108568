import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { uploadImages, startImageLoading } from "../store/ents/clientSlice";

export const id = "present-sendPhoto-ID";

const SendPhoto = () => {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.client.id);
  const active = useSelector((state) => state.client.imageLoading);

  const getTotalSize = (files) => {
    var size = 0;
    for (var i = 0; i < files.length; i++) {
      size += files.item(i).size;
    }
    return size;
  };
  const handleChange = () => {
    const files = document.getElementById(id).files;
    const maxMb = 20;
    const maxFiles = 10;
    //max 10 files per upload & max 20mb

    if (files.length > maxFiles) {
      alert("Max 10 photos per upload. Please reduce your selection.");
      return;
    }
    if (getTotalSize(files) > 1000 * 1000 * maxMb) {
      alert("Please reduce your selection. Max 20Mb total size.");
      return;
    }
    dispatch(startImageLoading());
    //start upload
    var formdata = new FormData();
    formdata.append("clientId", clientId);
    for (var i = 0; i < files.length; i++) {
      //add each file from FileList
      formdata.append("client-images", files[i]);
    }
    dispatch(uploadImages(formdata));
  };
  return (
    <>
      <input
        id={id}
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        multiple
        onChange={handleChange}
      />
      <Dimmer active={active}>
        <Loader size="large" content="Uploading" />
      </Dimmer>
    </>
  );
};

export default SendPhoto;
