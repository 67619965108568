import React from "react";
import ClientWelcome, { Logo } from "./comp/ClientWelcome";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:clientId">
          <ClientWelcome />
        </Route>
        <Route path="/">
          <Logo msg="Please contact us to book an appointment" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
